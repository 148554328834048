export enum ECivility {
    Monsieur,
    Madame,
    Autre
}


export enum ETypeLine {
  CollaboratorLine=0,
  AffairLine=1,
  FreeLine=2,
  Title=3,
  SubTitle=4,
}

export enum ESmsStatus {
    Encours,
    Envoye,
    Error
}

export enum ECustomerType {
    Particulier,
    Professionnel
}