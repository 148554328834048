var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-edit-user",attrs:{"id":"modal-edit-user","centered":"","title":"Ajouter un client","size":"lg"},on:{"ok":_vm.create},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v(" Ajouter ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Annuler ")])],1)]}}])},[(_vm.isCreatingCompany)?_c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Chargement...")])]),_c('br'),_vm._v(" Chargement des détails du client... ")])]):_c('validation-observer',{ref:"formCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label-for":"customerType"}},[_c('validation-provider',{attrs:{"name":"CustomerType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing mb-1",attrs:{"options":_vm.optionCustomerType,"value-field":"value","text-field":"label","disabled-field":"disabled"},model:{value:(_vm.customer.customerTypeRef),callback:function ($$v) {_vm.$set(_vm.customer, "customerTypeRef", $$v)},expression:"customer.customerTypeRef"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"left","id":"color","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.color),callback:function ($$v) {_vm.$set(_vm.customer, "color", $$v)},expression:"customer.color"}})]}}])})],1)],1)],1)],1),(_vm.customer.customerTypeRef == 1)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nom de la société *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":_vm.customer.customerTypeRef == 1 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1681602743)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Civilité","label-for":"civility"}},[_c('validation-provider',{attrs:{"name":"Civility"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"civility","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionCivility},model:{value:(_vm.customer.civility),callback:function ($$v) {_vm.$set(_vm.customer, "civility", $$v)},expression:"customer.civility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.customer.customerTypeRef == 0 ? 'Nom *' : 'Nom',"label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"Lastname","rules":_vm.customer.customerTypeRef == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.lastName),callback:function ($$v) {_vm.$set(_vm.customer, "lastName", $$v)},expression:"customer.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Prénom","label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"Firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.firstName),callback:function ($$v) {_vm.$set(_vm.customer, "firstName", $$v)},expression:"customer.firstName"}})]}}])})],1)],1)],1)],1),_vm._l((_vm.customer.addresses),function(itemAddress,indexAddress){return _c('b-col',{key:indexAddress,attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":'labelAddress_' + indexAddress}},[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex w-full justify-content-between"},[_c('label',{attrs:{"for":'labelAddress_' + indexAddress}},[_vm._v(_vm._s(_vm.customer.addresses.length > 1 ? "Adresse " + (indexAddress + 1) : "Adresse"))]),(_vm.customer.addresses.length > 1)?_c('div',{staticClass:"flex"},[(itemAddress.default)?_c('check-circle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Adresse par défaut'),expression:"'Adresse par défaut'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-class",attrs:{"color":itemAddress.default ? 'green' : '',"size":"1.5x"}}):_c('star-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.addFavoriteAddress(indexAddress)}}}),_c('trash-2-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.deleteAddress(itemAddress, indexAddress)}}})],1):_vm._e()])]),_c('validation-provider',{attrs:{"name":"LabelAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.customer.addresses.length > 1)?_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'labelAddress_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Libellé (optionnel)"},model:{value:(itemAddress.label),callback:function ($$v) {_vm.$set(itemAddress, "label", $$v)},expression:"itemAddress.label"}}):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'address_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Rue et n° de rue"},model:{value:(itemAddress.address),callback:function ($$v) {_vm.$set(itemAddress, "address", $$v)},expression:"itemAddress.address"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"AddressComplement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'addressComplement_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Bâtiment, Appartement.."},model:{value:(itemAddress.addressComplement),callback:function ($$v) {_vm.$set(itemAddress, "addressComplement", $$v)},expression:"itemAddress.addressComplement"}})]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"Zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'zipcode_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Code postal"},model:{value:(itemAddress.zipCode),callback:function ($$v) {_vm.$set(itemAddress, "zipCode", $$v)},expression:"itemAddress.zipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'city_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Ville"},model:{value:(itemAddress.city),callback:function ($$v) {_vm.$set(itemAddress, "city", $$v)},expression:"itemAddress.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('validation-provider',{attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":'country_' + indexAddress,"state":errors.length > 0 ? false : null,"placeholder":"Pays"},model:{value:(itemAddress.country),callback:function ($$v) {_vm.$set(itemAddress, "country", $$v)},expression:"itemAddress.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1)}),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.addAddress}},[_vm._v("+ Ajouter une adresse")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_vm._l((_vm.customer.emails),function(itemEmail,indexEmail){return _c('b-row',{key:indexEmail},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":'labelEmail' + indexEmail}},[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex w-full justify-content-between"},[_c('label',{attrs:{"for":'labelEmail' + indexEmail}},[_vm._v(_vm._s(_vm.customer.emails.length > 1 ? "Email " + (indexEmail + 1) : "Email"))]),(_vm.customer.emails.length > 1)?_c('div',[(itemEmail.default)?_c('check-circle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Email par défaut'),expression:"'Email par défaut'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-class",attrs:{"color":itemEmail.default ? 'green' : '',"size":"1.5x"}}):_c('star-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.addFavoriteEmail(indexEmail)}}}),_c('trash-2-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.deleteEmail(itemEmail, indexEmail)}}})],1):_vm._e()])]),(_vm.customer.emails.length > 1)?_c('validation-provider',{attrs:{"name":"labelEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"labelEmail","state":errors.length > 0 ? false : null,"placeholder":"Libellé (optionnel)"},model:{value:(itemEmail.label),callback:function ($$v) {_vm.$set(itemEmail, "label", $$v)},expression:"itemEmail.label"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null},model:{value:(itemEmail.email),callback:function ($$v) {_vm.$set(itemEmail, "email", $$v)},expression:"itemEmail.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1)],1)}),_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.addEmail}},[_vm._v("+ Ajouter un email")])],2),_c('b-col',{attrs:{"cols":"6"}},[_vm._l((_vm.customer.phones),function(itemPhone,indexPhone){return _c('b-row',{key:indexPhone},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":'labelPhone' + indexPhone}},[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex w-full justify-content-between"},[_c('label',{attrs:{"for":'labelPhone' + indexPhone}},[_vm._v(_vm._s(_vm.customer.phones.length > 1 ? "Téléphone " + (indexPhone + 1) : "Téléphone"))]),(_vm.customer.phones.length > 1)?_c('div',[(itemPhone.default)?_c('check-circle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Téléphone par défaut'),expression:"'Téléphone par défaut'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-class",attrs:{"color":itemPhone.default ? 'green' : '',"size":"1.5x"}}):_c('star-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.addFavoritePhone(indexPhone)}}}),_c('trash-2-icon',{staticClass:"cursor-pointer custom-class",attrs:{"size":"1.5x"},on:{"click":function($event){return _vm.deletePhone(itemPhone, indexPhone)}}})],1):_vm._e()])]),(_vm.customer.phones.length > 1)?_c('validation-provider',{attrs:{"name":"labelPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"id":"labelPhone","state":errors.length > 0 ? false : null,"placeholder":"Libellé (optionnel)"},model:{value:(itemPhone.label),callback:function ($$v) {_vm.$set(itemPhone, "label", $$v)},expression:"itemPhone.label"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Phone","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null},model:{value:(itemPhone.phone),callback:function ($$v) {_vm.$set(itemPhone, "phone", $$v)},expression:"itemPhone.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1)],1)}),_c('small',{staticClass:"cursor-pointer text-primary",on:{"click":_vm.addPhone}},[_vm._v("+ Ajouter un téléphone")])],2)],1)],1),_c('b-col',{staticClass:"mb-1 mt-1",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.displayMore = !_vm.displayMore}}},[_vm._v(_vm._s(_vm.displayMore ? "Moins.." : "Plus.."))])],1),(_vm.displayMore)?_c('b-col',{attrs:{"cols":"12"}},[(_vm.customer.customerTypeRef == 1)?_c('b-form-group',{attrs:{"label":"Siret","label-for":"siret"}},[_c('validation-provider',{attrs:{"name":"Siret"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"siret","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.siret),callback:function ($$v) {_vm.$set(_vm.customer, "siret", $$v)},expression:"customer.siret"}})]}}],null,false,1627752742)})],1):_vm._e(),(_vm.customer.customerTypeRef == 1)?_c('b-form-group',{attrs:{"label":"Numéro de TVA","label-for":"tvaNumber"}},[_c('validation-provider',{attrs:{"name":"TvaNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tvaNumber","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.tvaNumber),callback:function ($$v) {_vm.$set(_vm.customer, "tvaNumber", $$v)},expression:"customer.tvaNumber"}})]}}],null,false,4084419654)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Compte client","label-for":"customerAccount"}},[_c('validation-provider',{attrs:{"name":"CustomerAccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customerAccount","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.customerAccount),callback:function ($$v) {_vm.$set(_vm.customer, "customerAccount", $$v)},expression:"customer.customerAccount"}})]}}],null,false,1757575270)})],1),_c('b-form-group',{attrs:{"label":"Tags","label-for":"tags"}},[_c('validation-provider',{attrs:{"name":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","taggable":"","push-tags":"","id":"tags","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé ")]]},proxy:true}],null,true)},[_c('li',{staticClass:"border-top p-1",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"w-100",attrs:{"variant":"primary"}},[_vm._v("+ Nouveau tag")])],1)])]}}],null,false,1918645614)})],1),_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"notes","state":errors.length > 0 ? false : null,"rows":"3"},model:{value:(_vm.customer.notes),callback:function ($$v) {_vm.$set(_vm.customer, "notes", $$v)},expression:"customer.notes"}})]}}],null,false,1935685158)})],1),_c('b-form-group',{attrs:{"label":"Contacts associés","label-for":"contactsAssociated"}},[_c('validation-provider',{attrs:{"name":"ContactsAssociated"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1269659106)})],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }